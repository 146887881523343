.timeline-main {
  .stepper {
    &.stepper-vertical {
      &.timeline {
        li {
          a {
            padding: 0px 24px;
            left: 50%;
            @media (max-width: 450px) {
              left: 6%;
            }
            @media (min-width: 451px) and (max-width: 1025px) {
              left: 6%;
            }
            .circle {
              width: 50px;
              height: 50px;
              line-height: 50px;
              font-size: 1.4em;
              text-align: center;
              position: absolute;
              top: 16px;
              margin-left: -50px;
              background-color: #ccc;
              z-index: 2;
            }
          }
          .step-content {
            width: 45%;
            float: left;
            border-radius: 2px;
            position: relative;
            @media (max-width: 450px) {
              width: 80%;
              left: 3rem;
              margin-right: 3rem;
              margin-bottom: 2rem;
              float: right;
            }
            @media (min-width: 451px) and (max-width: 1025px) {
              width: 85%;
              left: 3rem;
              margin-right: 3rem;
              margin-bottom: 2rem;
              float: right;
            }
            &:before {
              position: absolute;
              top: 26px;
              right: -15px;
              display: inline-block;
              border-top: 15px solid transparent;
              border-left: 15px solid #e0e0e0;
              border-right: 0 solid #e0e0e0;
              border-bottom: 15px solid transparent;
              content: " ";
              @media (max-width: 450px) {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
              }
              @media (min-width: 451px) and (max-width: 1025px) {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
              }
            }
            &:after {
              position: absolute;
              top: 27px;
              right: -14px;
              display: inline-block;
              border-top: 14px solid transparent;
              border-left: 14px solid #fff;
              border-right: 0 solid #fff;
              border-bottom: 14px solid transparent;
              content: " ";
              @media (max-width: 450px) {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
              }
              @media (min-width: 451px) and (max-width: 1025px) {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
              }
            }
          }
          &.timeline-inverted {
            align-items: flex-end;
            .step-content {
              float: right;
              &:before {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
              }
              &:after {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
              }
            }
          }
          &:not(:last-child):after {
            content: " ";
            position: absolute;
            width: 3px;
            background-color: #e0e0e0;
            left: 50%;
            top: 65px;
            margin-left: -1.5px;
            @media (max-width: 450px) {
              left: 6%;
            }
            @media (min-width: 451px) and (max-width: 1025px) {
              left: 6%;
            }
          }
          @media (max-width: 1025px) {
            align-items: flex-end;
          }
        }
        &.colorful-timeline {
          li {
            .step-content {
              &:before {
                top: 1rem;
                border-left: 15px solid #D32F2F;
                border-right: 0 solid #D32F2F;
                @media (max-width: 450px) {
                  border-left-width: 0;
                  border-right-width: 15px;
                  left: -15px;
                  right: auto;
                }
                @media (min-width: 451px) and (max-width: 1025px) {
                  border-left-width: 0;
                  border-right-width: 15px;
                  left: -15px;
                  right: auto;
                }
              }
              &:after {
                top: 1rem;
                border-left: 14px solid #D32F2F;
                border-right: 0 solid #D32F2F;
                @media (max-width: 450px) {
                  border-left-width: 0;
                  border-right-width: 14px;
                  left: -14px;
                  right: auto;
                }
                @media (min-width: 451px) and (max-width: 1025px) {
                  border-left-width: 0;
                  border-right-width: 14px;
                  left: -14px;
                  right: auto;
                }
              }
              .timeline-header {
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
              }
            }
            &.timeline-inverted {
              .step-content {
                &:before {
                  border-left-width: 0;
                  border-right-width: 15px;
                  left: -15px;
                }
                &:after {
                  border-left-width: 0;
                  border-right-width: 14px;
                  left: -14px;
                }
              }
            }
            &:not(:last-child):after {
              background-color: #D32F2F;
            }
          }
        }
        &.timeline-animated {
          .more-padding {
            padding-right: 100px;
            padding-left: 100px;
          }
          li {
            transition: all 0.7s ease-in-out;
          }
        }
        &.timeline-simple {
          li {
            a {
              padding: 0px;
              .circle {
                width: 23px;
                height: 23px;
                line-height: 23px;
                font-size: 1.4em;
                text-align: center;
                position: absolute;
                top: 16px;
                margin-left: -12px;
                background-color: #ccc;
                z-index: 2;
              }
            }
            .step-content {
              width: 45%;
              float: left;
              border-radius: 2px;
              position: relative;
              @media (max-width: 450px) {
                width: 80%;
                left: 3rem;
                margin-right: 3rem;
                margin-bottom: 2rem;
                float: right;
              }
              @media (min-width: 451px) and (max-width: 1025px) {
                width: 85%;
                left: 3rem;
                margin-right: 3rem;
                margin-bottom: 2rem;
                float: right;
              }
              &:before {
                position: absolute;
                top: 15px;
                display: inline-block;
                border-top: 15px solid transparent;
                border-left: 15px solid #e0e0e0;
                border-right: 0 solid #e0e0e0;
                border-bottom: 15px solid transparent;
                content: " ";
                @media (max-width: 450px) {
                  border-left-width: 0;
                  border-right-width: 15px;
                  left: -15px;
                  right: auto;
                }
                @media (min-width: 451px) and (max-width: 1025px) {
                  border-left-width: 0;
                  border-right-width: 15px;
                  left: -15px;
                  right: auto;
                }
              }
              &:after {
                position: absolute;
                top: 15px;
                display: inline-block;
                border-top: 14px solid transparent;
                border-left: 14px solid #fff;
                border-right: 0 solid #fff;
                border-bottom: 14px solid transparent;
                content: " ";
                @media (max-width: 450px) {
                  border-left-width: 0;
                  border-right-width: 14px;
                  left: -14px;
                  right: auto;
                }
                @media (min-width: 451px) and (max-width: 1025px) {
                  border-left-width: 0;
                  border-right-width: 14px;
                  left: -14px;
                  right: auto;
                }
              }
              &.hoverable {
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
                &:hover {
                  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                }
              }
            }
            &:not(:last-child):after {
              width: 2px;
              background-color: #9e9e9e;
              top: 32px;
              height: 100%;
            }
            &.timeline-inverted {
              .step-content {
                &:before {
                  border-left-width: 0;
                  border-right-width: 15px;
                  left: -15px;
                }
                &:after {
                  border-left-width: 0;
                  border-right-width: 14px;
                  left: -14px;
                }
              }
            }
          }
        }
        &.timeline-images {
          li {
            a {
              .circle {
                font-size: 1rem;
              }
            }
            &:not(:last-child):after {
              background-color: #26c6da;
            }
          }
        }
        &.timeline-basic,
        &.colorful-timeline,
        &.timeline-animated {
          li {
            a {
              @media (max-width: 450px) {
                left: 8%;
              }
              .circle {
                @media (max-width: 450px) {
                  width: 30px;
                  height: 30px;
                  line-height: 30px;
                  font-size: .9em;
                  top: 26px;
                  margin-left: -35px;
                }
              }
            }
            &:not(:last-child):after {
              @media (max-width: 450px) {
                left: 9%;
                height: 100%;
                top: 55px;
              }
            }
          }
        }
        &.timeline-simple,
        &.timeline-images {
          li {
            a {
              @media (max-width: 450px) {
                left: 10%;
              }
              .circle {
                @media (max-width: 450px) {
                  top: 18px;
                }
              }
            }
            &:not(:last-child):after {
              @media (max-width: 450px) {
                left: 10%;
              }
            }
          }
        }
      }
    }
  }
}
