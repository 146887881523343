.accountHeader {
	text-align: end;
	position: absolute;
	right: 10px;
}

.individualNote {
	justify-content: space-between;
}

.notesHeaderRear {
	color: #ffffff;
	text-align: end;
	position: absolute;
	right: 1.5em;
}

.listGroupHover {
	cursor: pointer;
}

.listGroupHover:hover {
	color: white;
	background-color: #1976d2;
	opacity: 0.7;
}


.notificationHover {
	cursor: pointer;
}

.notificationHover:hover {
	background-color: #1976d2;
	opacity: 0.7;
}
