// These settings will be only for one version
// Masonry layout vertical
.masonry-with-columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 1000px;
    div {
      flex: 1 0 auto;
      background: #00997B;
      color: white;
      margin: 0 1rem 1rem 0;
      text-align: center;
      font-weight: 900;
      font-size: 2rem;
    } 
    @for $i from 1 through 36 { 
      div:nth-child(#{$i}) {
        $h: (random(400) + 100) + px;
        height: $h;
        line-height: $h;
      }
    }
  }
  
  // Masonry layout horizontal
  .masonry-with-columns-2 {
    display: flex;
    flex-wrap: wrap;
    div {
      height: 150px;
      line-height: 150px;
      background: #9B1B30;
      color: white;
      margin: 0 1rem 1rem 0;
      text-align: center;
      font-weight: 900;
      font-size: 2rem;
      flex: 1 0 auto;
    } 
    @for $i from 1 through 36 { 
      div:nth-child(#{$i}) {
        $h: (random(400) + 70) + px;
        width: $h;
      }
    }
  }
  
  // Masonry layout flex
  .masonry-with-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 1000px;
    div {
      width: auto;
      background: #975A58;
      color: white;
      margin: 0 1rem 1rem 0;
      text-align: center;
      font-weight: 900;
      font-size: 2rem;
    } 
    @for $i from 1 through 36 { 
      div:nth-child(#{$i}) {
        $h: (random(400) + 100) + px;
        height: $h;
        line-height: $h;
      }
    }
  }