.disabledInput {
    border: 1px solid #d8d8d8;
    background-color: #f2f2f2 !important;
    color: #8a8a8a !important;
}

.activeInput {
    border: 1px solid #000;
}

.invalidTextarea {
    border: 2px solid red !important;
}

.normalTextarea {
    border: 1px solid #d8d8d8 !important;
}

.md-form.md-outline,
div.form-control {
    margin-top: 0 !important;
}
 