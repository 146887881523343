:root{
	/*Primary*/
	--rm-blue:  #5881c1;
	--rm-darkBlue: #46679A;
	--rm-lightBlue: #a1c1e6;
	--rm-white: #fff;
	--rm-secondaryWhite: #eee;
	--rm-gray: #ddd;
	--rm-lightGray: #e8e8e8;
	--rm-darkGray: #c1c1c1;
	
	--maxHDrop: 90px;
}
.App {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.App-logo {
	width: 10rem;
}

.App-background {
	background-color: #858585;
}
.mainBackground {
	background-position: center;
	background-size: auto;
	background-repeat: repeat;
}

.App-main {
	padding-top: 46px;
}

.App-footer {
	color: #eeeeee !important;
	background-color: #5881c1;
	padding-top: 4px;
	padding-bottom: 4px;
}

.dashboardStatusCardText {
	color: white;
	font-size: 1.8em;
	font-weight: 500;
}

.dbTextLarge {
	font-size: 3em;
	font-weight: 500;
}

.flyout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: space-between;
}

.home-feature-box .fa {
	font-size: 6rem;
}

.home-feature-box span {
	display: block;
	color: #111;
	font-weight: bold;
	margin-top: 1.5rem;
}

.example-components-list li > a {
	color: #495057;
}

.example-components-list li:last-child > a {
	border-bottom: 0;
}

.example-components-list li > a .fa {
	color: rgba(0, 0, 0, 0.35);
	float: right;
}

.green-gradient {
	background: linear-gradient(40deg, #99fc3f, #389f36) !important;
}

.red-gradient {
	background: linear-gradient(40deg, #d9534f, #990000) !important;
}

.check-form .form-check-label {
	color: rgba(0, 0, 0, 0);
}

.date-picker-form .md-form {
	margin: 0;
}

.date-picker-form .MuiInput-underline-9:before,
.date-picker-form .MuiInput-underline-9:after,
.date-picker-form .md-form input[type="text"] {
	border-bottom: none !important;
}

.dropdown-form-control {
	padding: 0px;
}

.dropdown-form-control .dropdown-toggle::after {
	float: right;
	margin-top: 0.5em;
}

/*Internal Details Dropdowns*/
.idDropdownSales {
	background-color: #ffffff;
}

.idDropdownService {
	background-color: #376fbf;
}

.idDropdownClinic {
	background-color: #3357af;
}

.idDropdownPatient {
	border: 1px solid #dbdbdb;
	background-color: #FFFFFF;
}

.idDropdownMarketing {
	background-color: #3045a3;
}

.idDropdownPatient {
	border: 1px solid #dbdbdb;
	background-color: #FFFFFF;
}

.idDropdown {
	float: right;
	border-radius: 3px;
	display: block;
	width: 100%;
	margin-bottom: 0.2rem;
}
.idDropdownToggleP {
	color: black;
}

.idDropdownToggle {
	color: black;
}

.idDropdownToggle::after {
	float: right;
	margin-top: 0.5em;
}

.dropdownInForm {
	margin-top: 1em;
}
/*end*/

/*Note containers*/
.pvNotesGroupOuterContainer {
	overflow-x: auto;
	overflow-y: auto;
	max-height: 65vh;
}

.pvNotesGroupInnerContainer {
	/*white-space: nowrap;*/
}

.pvNoteContainer {
	margin-left: auto;
	margin-right: auto;
	max-width: 95%;
}

.pvNoteText {
	white-space: normal;
}

.pvButtonFloat {
	float: right;
}

/*end*/

/* Input Style */

.input-style-light {
	border: 1px solid #ced4da;
	background: #ffffff;
	color: #495057;
}

.input-style-dark,
.input-style-dark:focus {
	border: 1px solid #7a7575;
	background: #555555;
	color: #ffffff;
}

.input-style-dark::-webkit-input-placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #aaa;
}

.input-style-dark::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #aaa;
}

.input-style-dark::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #aaa;
}
.input-style-dark::-moz-placeholder {
	/* Firefox 19+ */
	color: #aaa;
}
.input-style-dark :-ms-input-placeholder {
	/* IE 10+ */
	color: #aaa;
}

.input-style-dark:focus {
	border-color: rgba(126, 239, 104, 0.8);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
		0 0 8px rgba(126, 239, 104, 0.6);
	outline: 0 none;
}

/* Table Box Shadow */

.table-box-shadow {
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.table-box-shadow tbody tr td {
	vertical-align: middle;
	padding: 0.1em;
}

.table-box-shadow tbody tr td div.my-3 {
	margin: 0em !important;
}

.table-box-shadow tbody tr td .form-check-label {
	margin-right: 1em !important;
	width: 0px;
	overflow: hidden;
}

/*.img-thumbnail{*/
/*z-index: 0;*/
/*}*/

.img-thumbnail {
	position: relative;
	width: auto !important;
	height: auto !important;
	cursor: crosshair;
}

.expenseDateOptions .md-form {
	color: white;

}

.expenseDateOptions .md-form input {
	color: white;
	font-weight: bold;


}

.expenseDateOptions .md-form input[type="text"]:focus {
	border-bottom: 1px solid #ced4da;
	box-shadow: 0 1px 0 0 #ced4da;
}

.expenseDateOptions .md-form input[type="text"]:focus + label {
	color: white;


}

.expenseDateOptions .md-form label {
	color: #ffffff;
	font-size: 12px;
}

.expenseDateOptions .md-form div {
	display: flex;
}

.expenseDateOptions .md-form button {
	color: white;
}

.expenseDateOptions .md-form div::before {
	border-bottom-color: #ced4da;
}

.expenseImage:hover {
	transform: scale(
		1.5
	); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.filterSearchBoxCol {
	margin-top: auto;
	margin-bottom: auto;
	display: block;
}

.filterSearchBoxCardBody {
	padding-top: 0;
	padding-bottom: 0;
}

.w-60 {
	width: 60% !important;
}

.expensesGrid {
	margin-bottom: 1%;
	z-index: 0;
}

td[class^="MuiTableCell-"] {
	white-space: pre-wrap !important;
}

.downloadCsvDiv {
	pointer-events: none;
	z-index: 5000;
}

.downloadCsvButton {
	float: right;
	pointer-events: auto;
}

.reportsDateOutline fieldset {
	background-color: white;
}

.reportsDateOutline input {
	color: black;
	z-index: 500;
}

.reportsDateOutline fieldset legend {
	width: auto;
	border-color: white;
}

.reportsSelects input {
	max-height: 26px;
}

.containerMargin {
	margin: 6px 0 !important;
}

.noMargin {
	margin-top: 0px !important;
}

.smallerText tbody tr td {
	font-size: 12px;
	padding: 4px;
}

.smallerText tbody tr:hover {
	background-color: rgba(88, 129, 183, 0.6);
}
.customNavDropdown .dropdown-menu{
	max-height: 500px;
}

.mainBackground {
	background-position: center;
	background-size: auto;
	background-repeat: repeat;
}

.fieldOpsDropdown .dropdown-toggle::after{
	padding-bottom: 8px;

}

.popoverHeader {
	background-color: #aa66cc;
	color: white;
}

.popoverBody {
	width: 500px;
	background-color: white;
	box-shadow: 5px 5px 15px 5px #777777;
}

.MuiBackdrop-root{
	background-color: rgba(0,0,0,0.25) !important;
}
.salesPilotModal .modal-content{
	background-color: transparent!important;
	border-radius: 30px!important;
}

.salesPilotModal .modal-header{
	justify-content: center;
	border-radius: 5px 5px 0px 0px!important;
	background-color: #5881C1;
	color: white;
	border-bottom: 0px;
}
.salesPilotModal .modal-footer{
	/*border-top: 1px solid #5881C1;*/
	padding-bottom:0px;
	padding-top: 0px;
	background-color: #5881C1;
	border-radius: 0px 0px 5px 5px!important;
}

.salesPilotModal .modal-body{
	border-top: 0px;
	background-color: white;
	border-radius: 0px 0px 0px 0px!important;
	padding: 20px;
}

.inputIcon i {
	color: #757575;
}

.inputIcon input {
	margin-left: 20px!important;
}

.inputIcon label{
    padding-left: 5px!important;
	margin-left: 16px!important;
}

/*.modal-content{*/
/*	background-color: transparent;*/
/*	border-radius: 30px!important;*/
/*}*/

div.searchfield > div{
	z-index: 501;
}
 
.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    font-size: .865rem;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    background-color: #c1c1c1;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 1px;
}