/*I copied this css for some reason, don't judge me, Eathen made this*/
.informationCard .md-form input[type="text"],
.md-form input[type="email"],
.md-form input[type="time"],
.md-form input[type="date"],
.md-form input[type="datetime-local"],
.md-form input[type="tel"],
.md-form input[type="number"],
.md-form textarea.md-textarea {
	border: none;
	text-align: end;
}

.insuranceCard .md-form input[type="text"],
.md-form input[type="email"],
.md-form input[type="time"],
.md-form input[type="date"],
.md-form input[type="datetime-local"],
.md-form input[type="tel"],
.md-form input[type="number"],
.md-form textarea.md-textarea {
	border: none;
	text-align: end;
}

.informationView .md-form input[type="text"],
.md-form input[type="email"],
.md-form input[type="time"],
.md-form input[type="date"],
.md-form input[type="datetime-local"],
.md-form input[type="tel"],
.md-form input[type="number"],
.md-form textarea.md-textarea {
	margin-top: 0.2rem;
	margin-bottom: 0.2rem;
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
}

.informationView .md-form {
	margin-bottom: 0.5rem;
}

/* .newPatientModal .md-form div label{

} */

.backgroundFax{
	height:100% !important;
	width:100%;
	position: fixed;
	top: 50px;
}

.faxCard{

	box-shadow: 0px 0px 5px 100vw rgba(255, 0, 0, 0.28) !important;
	z-index: 1;
}

.noZoom:hover{
	transform:none;
}

.rotateImage  {
	-webkit-transform:rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.rotateImage :hover  {
	-webkit-transform:rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.thumbhoverend{
	z-index: 900!important;
}
.thumbhoverend:hover{
	transform: scale(2.4);
	position: absolute;
	right: 38%;
	top: 19%;
	z-index: 1000!important;
}

.thumbhover{
	z-index: 900!important;
}

.thumbhover:hover{
	transform: scale(2.2);
	position: absolute;
	left: 30%;
	top: 17%;
	z-index: 1000!important;
}

.newPatientModal .md-form div div {
	margin-top: 0;
}

.checkBoxLabel {
	text-align: right;
}

.userCheckbox div {
	margin-top: 2rem !important;
}

.userCheckbox .form-check-label {
	margin-right: 0 !important;
}

.datePickerOptions .md-form {
	margin-top: 14px;
	margin-bottom: 14px;
}

.datePickerOptions .md-form label {
	top: -8px;
	font-size: 13px;
}

.datePickerOptions .md-form div {
	padding-left: 0;
	display: flex;
}

.datePickerOptions .md-form div::before {
	border-bottom-color: #ced4da;
}

.informationView .datePickerOptions .md-form input {
	margin-bottom: 0;
	margin-top: 0;
}

.searchScaled {
	transform: scale(1.2);
}

.listSearchInputBackground {
	background-color: #ffffff;
}

.cardGroupListContainer {
	max-width: 96%;
	margin: auto;
}

.cardGroupList {
	display: flex;
	justify-content: space-around;
}

.cardGroupCard {
	margin: 1.5rem 0.5rem 2rem;
	min-width: 15%;
	max-width: 18%;
}

.cardGroupCardHeader {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
}

.editSpan {
	cursor: pointer;
	font-size: 16px;
	color: dodgerblue;
}

.patientHeaderRear {
	color: #ffffff;
	text-align: end;
	position: absolute;
	right: 1.5em;
}

.patientHeaderFront {
	color: #ffffff;
	text-align: start;
	position: absolute;
	left: 1.5em;
}

.patientHeaderFront:hover,
.patientHeaderRear:hover {
	color: #95df74;
}

.individualNote {
	justify-content: space-between;
}

.userFiltersModal .modal-content {
	background-color: transparent;
}

.userFiltersModal div.modal-content {
	box-shadow: none !important;
}

.faxOrderType .dropdown-content {
	max-height: 150px !important;
}

.blueHeader {
	background-color: #5881c1 !important;
}
.faxButtons {
	z-index: 0 !important;
}

.patientOrderView .modal-dialog {
	width: 40% !important;
	max-width: 40% !important;
	padding-left: 50px;
}

.patientOrderView .modal-content {
	background-color: transparent;
}

.patientOrderView div.modal-content {
	box-shadow: none !important;
}

.patientOrderView.modal-body {
	border-radius: 2px;
}

/* Cascading modal register / login  */
.form-cascading .modal-footer {
	border-top: none;
	flex-direction: column;
}

.form-cascading #options {
	margin-right: auto;
}

.form-cascading #options p {
	margin-bottom: 0;
}

.form-cascading #options p span {
	cursor: pointer;
}

/* Elegant modal login */
.form-elegant .modal-header {
	border-bottom: none;
}

.form-elegant .modal-footer {
	border-top: none;
	flex-direction: column;
}

.form-elegant .font-small {
	font-size: 0.8rem;
}

/* Dark modal register */
.form-dark .modal-content {
	background-image: url("https://mdbootstrap.com/img/Photos/Others/pricing-table%20(7).jpg");
}

.form-dark .modal-header {
	border-bottom: none;
}

.form-dark .modal-header .close {
	color: #fff;
}

.form-dark .modal-footer {
	border-top: none;
	flex-direction: column;
}

.form-dark input[type="email"]:focus:not([readonly]) {
	border-bottom: 1px solid #00c851;
	-webkit-box-shadow: 0 1px 0 0 #00c851;
	box-shadow: 0 1px 0 0 #00c851;
}

.form-dark input[type="email"]:focus:not([readonly]) + label {
	color: #fff;
}

.form-dark .font-small {
	font-size: 0.8rem;
}

.noteEditModal div .modal-dialog {
	max-width: 40%;
}

.complaintNotesTable {
	width: 100%;
}

.complaintNotesTable td,
.complaintNotesTable th {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
}

.complaintNotesTable tr:nth-child(even) {
	background-color: #dddddd;
}

/* gecoder */

.geocoder {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	border-radius: 3px;
	background: #fff;
	width: 80%;
}

.geocoder i {
	width: 20px;
	height: 20px;
	background: url(https://samples.thinkgeo.com/cloud/example/image/seach.png);
	z-index: 10000;
	position: absolute;
	top: 10px;
	left: 10px;
}

.geocoder input {
	display: inline-block;
	font: 15px/20px sans-serif;
	margin: 0;
	color: rgba(0, 0, 0, 0.6);
	padding: 10px 35px;
	-webkit-appearance: none;
	border: 0px solid;
	width: 100%;
	border-radius: 3px;
	z-index: 10001;
}

.geocoder input:focus {
	outline: none;
}

.resultWrap {
	z-index: 1000;
	position: absolute;
	background: white;
	border-radius: 8px;
	/*border: 1px solid grey;*/
	width: 100%;
	margin-left: -16px;
}

#geocoderResult {
	list-style: none;
	padding: 0;
	margin: 0;
	z-index: 1000;
}

#geocoderResult li {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#geocoderResult li a {
	padding: 5px 15px;
	display: inline-block;
	width: 100%;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0;
	color: #3887be;
	cursor: pointer;
}

#geocoderResult li a:hover {
	color: #63b6e5;
}

.ol-popup-closer {
	text-decoration: none;
	position: absolute;
	top: 2px;
	right: 8px;
	background-color: transparent;
	border: 0;
}

.ol-popup-closer:after {
	content: "✖";
}

.ptBtn {
	height: 38px;
	width: 38px;
	color: white;
	font-size: 0.65em;
	text-align: center;
	border-radius: 50%;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	line-height: 48px;
}

.cancelBtn {
	background-color: red;
}

.saveBtn {
	background-color: #00c851;
}

.nextBtn {
	background-color: #9771c8;
}

.noMargin {
	margin: 0;
}

div .file-field.md-form {
	margin-top: 0;
}

select.rdl-control {
	display: block !important;
}

.merge-modal {
	width: 90% !important;
	min-height: 70% !important;
}

.topMost {
	z-index: 10000;
}

.merge-modal-body {
	max-height: 500px;
	background-color: #ffffff;
	padding-right: 5%;
	padding-left: 5%;
}

.thumb-scroll {
	overflow-y: scroll;
}

.closeBtn:hover {
	color: lightslategrey !important;
}

.topBorder {
	position: absolute;
	background-color: white;
	width: 100%;
	border-radius: 8px 8px 0 0;
	margin-top: -5px;
	padding: 3px;
}

.bottomBorder {
	position: absolute;
	background-color: white;
	width: 100%;
	border-radius: 0 0 8px 8px;
	margin-top: 118px;
	padding: 3px;
}

.inputCheckWrapper div {
	padding-top: 2rem;
}

.inputBtnWrapper button {
	top: 0.8rem;
	left: 1rem;
}

.noteButtonsPositioning {
	position: absolute;
	right: -2px;
	top: -11px;
}

.noteButtonsYellow {
	background-color: #fff2ab !important;
}

.orderNotesModal div {
	background-color: #fff7d1;
}

.orderTasksModal div {
	background-color: #ffe4f1;
}

.attachmentsOtherField div {
	margin: 0px 0 15px 0 !important;
}

.TextField-without-border-radius fieldset {
	border-radius: 0;
}

.smallMargin {
	margin-top: 2px !important;
	margin-bottom: 2px !important;
}

.medMargin {
	margin-top: 6px !important;
	margin-bottom: 6px !important;
}

.searchField ul div {
	padding-left: 0;
	margin: 5px;
}

.uiDateOutline fieldset legend {
	width: auto;
}

.uiOutlines {
	padding-top: 15px;
}

.uiOutlines fieldset legend {
	width: auto;
}


.nestedNoPadding {
	padding-left: 0;
	padding-right: 0;
}

.checkbox-approved-filled
	[type="checkbox"][class*="filled-in"]:checked
	+ label:after {
	border-color: #ff8800;
	background-color: #ff8800;
}

.checkbox-warranty-filled
	[type="checkbox"][class*="filled-in"]:checked
	+ label:after {
	border-color: #c97f6f;
	background-color: #c97f6f;
}

.smallTable tbody td {
	padding-top: 2px !important;
	padding-bottom: 2px !important;
}

.tableInputContainer {
	margin: 0 !important;
}

.extraWidth {
	width: 100vw !important;
}

.stepContainer ul li div {
	padding: 4px !important;
	margin-left: 16px !important;
}

.card {
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.46), 0 5px 10px 0 rgba(0, 0, 0, 0.42);
}



.labelPosition {
	position: absolute;
	padding: 0 5px;
	background-color: white;
}

.demographicsLabel,
.tabContactInfoLabel {
	left: 30px;
	top: -5px;
}

.tabDiseaseInfoLabel {
	left: 30px;
	top: -13px;
}

.number-input input[type="number"] {
  -webkit-appearance: textfield;
    -moz-appearance: textfield;
          appearance: textfield;
}

.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input button {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  position: relative;
}

.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  height: 2px;
  transform: translate(-50%, -50%);
}

.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
  text-align: center;
}

.number-input.number-input {
  border: 1px solid #ced4da;
	width: 7rem;
  border-radius: .25rem;
}

.number-input.number-input button {
	width: 1.82rem;
  height: .7rem;
	vertical-align: middle;
}

.number-input.number-input button.minus {
  padding-left: 10px;
}

.number-input.number-input button:before,
.number-input.number-input button:after {
  width: .7rem;
  background-color: #495057;
}

.number-input.number-input input[type=number] {
  max-width: 4rem;
  padding: .5rem;
  border: 1px solid #ced4da;
  border-width: 0 1px;
  font-size: 1rem;
  height: 2rem;
  color: #495057;
}