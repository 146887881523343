// Toasts
.md-toast-title {
  font-weight: 400;
}

.md-toast-message {
  word-wrap: break-word;
}

.md-toast-message a,
.md-toast-message label {
  @extend .white-text;
}

.md-toast-message a:hover {
  color: $toast-message-a-hover-color;
  text-decoration: none;
}

.md-toast-close-button {
  @extend .white-text;
  position: relative;
  right: $toast-close-button-right;
  top: $toast-close-button-top;
  float: right;
  font-size: $toast-close-button-font-size;
  font-weight: 400;
  text-shadow: $toast-close-button-text-shadow $white-base;
  opacity: $toast-close-button-opacity;
  filter: $toast-close-button-filter;
}

.md-toast-close-button:hover,
.md-toast-close-button:focus {
  @extend .black-text;
  text-decoration: none;
  cursor: pointer;
  opacity: $toast-close-button-hover-opacity;
  filter: $toast-close-button-hover-filter;
}

button.md-toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
}

.md-toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.md-toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.md-toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.md-toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.md-toast-top-left {
  top: $toast-top-bottom-left-right-size;
  left: $toast-top-bottom-left-right-size;
}

.md-toast-top-right {
  top: $toast-top-bottom-left-right-size;
  right: $toast-top-bottom-left-right-size;
}

.md-toast-bottom-right {
  right: $toast-top-bottom-left-right-size;
  bottom: $toast-top-bottom-left-right-size;
}

.md-toast-bottom-left {
  bottom: $toast-top-bottom-left-right-size;
  left: $toast-top-bottom-left-right-size;
}

#toast-container {
  position: fixed;
  z-index: 999999;
}

#toast-container * {
  box-sizing: border-box;
}

#toast-container>div {
  box-shadow: $z-depth-1;
  @extend .white-text;
  position: relative;
  overflow: hidden;
  margin: 0 0 $toast-container-margin;
  padding: $toast-container-py $toast-container-pr $toast-container-py $toast-container-pl;
  width: $toast-container-width;
  background-position: $toast-container-py center;
  background-repeat: no-repeat;
  opacity: $toast-container-opacity;
  filter: $toast-container-filter;
}

#toast-container> :hover {
  box-shadow: $z-depth-2;
  transition: $toast-container-hover-transition;
  opacity: $toast-container-hover-opacity;
  filter: $toast-container-hover-filter;
  cursor: pointer;
}

#toast-container.md-toast-top-center>div,
#toast-container.md-toast-bottom-center>div {
  width: $toast-container-width;
  margin: auto;
}

#toast-container.md-toast-top-full-width>div,
#toast-container.md-toast-bottom-full-width>div {
  width: $toast-container-top-full-width;
  margin: auto;
}

.md-toast {
  background-color: $toast-bgc;
}

.md-toast-success {
  background-color: $success-color;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

.md-toast-error {
  background-color: $danger-color;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

.md-toast-info {
  background-color: $info-color;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

.md-toast-warning {
  background-color: $warning-color;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

.md-toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: $toast-progress-height;
  background-color: $black-base;
  opacity: $toast-progress-opacity;
  filter: $toast-progress-filter;
}
