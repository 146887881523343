/* ==========================================================================
   $BASE-PICKER
   ========================================================================== */
/**
 * Note: the root picker element should *NOT* be styled more than what's here.
 */
/**
 * The picker input element.
 */
.picker__input {
  cursor: default;
}
/**
 * When the picker is opened, the input element is "activated".
 */
.picker__input.picker__input--active {
  border-color: $pickerinput-active-border-color;
}
/**
 * Note: the root picker element should *NOT* be styled more than what's here.
 */
.picker {
  font-size: $picker-font-size;
  text-align: center;
  line-height: $picker-font-line-height;
  color: $black;
  position: absolute;
  z-index: 10000;
  user-select: none;
  /**
   * The holder is the only "scrollable" top-level container element. The holder should overlay the entire screen.
   */
  .picker__holder {
    width: 100%;
    overflow-y: auto;
    overflow-scrolling: touch;
    position: fixed;
    @include transition-main($picker-holder-transition-bg, $picker-holder-transition-top);
    backface-visibility: hidden;
  }
  /*!
   * Default mobile-first, responsive styling for pickadate.js
   * Demo: http://amsul.github.io/pickadate.js
   */
  /**
   * Note: the root picker element should *NOT* be styled more than what's here.
   */
  /**
   * Make the holder and frame fullscreen.
   */
  .picker__holder,
  .picker__frame {
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
  }
  /**
   * The frame that bounds the box contents of the picker.
   */
  .picker__frame {
    position: absolute;
    margin: 0 auto;
    min-width: $picker-frame-min-width;
    max-width: $picker-frame-max-width;
    //  picker width
    width: $picker-frame-width;
    max-height: $picker-frame-max-height;
    filter: $picker-frame-filter;
    opacity: 0;
    @include transition-main($picker-frame-transition);
    @media (min-height: $pickerframe-wrap-box-breakpoint) {
      overflow: visible;
      top: auto;
      bottom: $picker-frame-media-bottom;
      max-height: $picker-frame-media-max-height;
    }
    @media (min-height: $pickerframe-mb-breakpoint) {
      margin-bottom: $picker-frame-media-mb;
    }
    /**
     * The wrapper sets the stage to vertically align the box contents.
     */
    .picker__wrap {
      display: table;
      width: 100%;
      height: 100%;
      @media (min-height: $pickerframe-wrap-box-breakpoint) {
        display: block;
      }
    }

  }
  /**
   * The box contains all the picker contents.
   */
  .picker__box {
    background: $white;
    display: table-cell;
    vertical-align: middle;
    @media (min-height: $pickerframe-wrap-box-breakpoint) {
      display: block;
      border: $picker-box-border solid $picker-box-border-color;
      border-top-color: $picker-box-border-top-color;
      border-bottom-width: 0;
      border-radius: $picker-box-border-radius;
      box-shadow: $picker-box-box-shadow;
    }
  }
}


/**
 * When the picker opens...
 */
.picker--opened {
  .picker__holder {
    top: 0;
    background: transparent;
    zoom: $picker-opened-holder-zoom;
    background: $picker-opened-holder-bg;
    @include transition-main($picker-opened-holder-transition);
  }
  .picker__frame {
    top: 0;
    filter: $picker-opened-frame-filter;
    opacity: $picker-opened-frame-opacity;
    @media (min-height: $pickerframe-pos-breakpoint) {
      top: $picker-opened-frame-media-top;
      bottom: auto;
    }
  }
}


/**
 * For `large` screens, transform into an inline picker.
 */

/* ==========================================================================
   CUSTOM MATERIALIZE STYLES
   ========================================================================== */

.datepicker.picker__input.picker__input--active,
.timepicker.picker__input.picker__input--active {
  border-bottom: $picker-custom-materialize-styles;
}
