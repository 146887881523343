.dot {
	height: 20px;
	width: 20px;
	margin-right: 1%;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}

.activeDot {
	height: 20px;
	width: 20px;
	background-color: #0099cc;
	border-radius: 50%;
	margin-right: 1%;
	display: inline-block;
}

.prezContainer {
	justify-content: center;
}

.topRow {
	display: flex;
	justify-content: center;
}

.mainCard {
	display: inline-block;
}

.topCardDiv {
	background-color: #0099cc;
}

.topCardBody {
	background-color: #3b3b3b;
	border-radius: 0 0 5px 5px;
}

.badge {
	size: A5;
}

.topRow {
	margin-bottom: 5%;
	margin-right: 5%;
}

.topCard {
	border-radius: 5px;
}

.dotDiv {
	margin: 5% 0 0 27%;
}

.listItem {
	border-radius: 5px;
	margin-bottom: 3%;
}

.listGroup {
	width: 22rem;
}
