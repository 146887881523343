.dashboardText {
  color: #eeeeee;
  font-weight: 400;
}

.dashboardLine {
  border-color: #eeeeee;
}

.patientSearchDiv .md-form input[type="text"] {
  font-size: 26px;
}
.dashButton {
  border: 2px solid var(--rm-blue);
  font-weight: bold;
  color: var(--rm-blue);
  background-color: #ffffff !important;
  font-size: 11px !important;
  box-shadow: 4px 4px 3px 3px rgba(0, 0, 0, 0.45) !important;
}

.patientSearchDiv .md-form input[type="text"]:focus:not([readonly]) {
  border-bottom: 1px solid #ffffff;
  box-shadow: 0 1px 0 0 #dddddd;
}

.patientSearchDiv ::placeholder {
  color: #ffffff;
  opacity: 0.7;
}

.badgeLocation {
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 20px;
}

.searchDropdownDiv .btn-secondary.dropdown-toggle {
  background-color: #ff5722 !important;
  display: inline-block;
}

.w-90 {
  width: 90% !important;
}

.verticalPadding {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.preloader-wrapper {
  width: 8rem;
  height: 8rem;
}

.dbNoteHeader {
  color: white;
  display: flex;
  justify-content: space-between;
}

.dbNoteCompleteButton {
  float: right;
}

.dbStatusCards {
  width: 100%;
  height: 100%;
  padding: 1em;
}

.statusNew {
  background-color: var(--rm-blue);
  margin: 20px;
  height: 250px;
}

.statusProcess {
  background-color: #6f95d1;
  margin: 20px;
  height: 250px;
}

.statusDelivery {
  background-color: #8aabe0;
  margin: 20px;
  height: 250px;
}
.dbStatusCardsText {
  color: var(--rm-blue);
  /*color: #ffffff;*/
  font-size: 0.9em;
}

.dbStatusCardsText :hover {
  color: #ffe88b;
  text-decoration: underline;
}

.statusHost {
  text-align: center;
  cursor: pointer;
}

.reasonHost {
  text-align: right;
  font-weight: 500;
}

.reasonHostLink {
  cursor: pointer;
  margin-bottom: 4px;
}

.titleCardLong {
  float: right;
  position: relative;
  top: -40px;
  left: 15px;
}

.monthGlanceCardRow {
  background-color: var(--rm-blue);
  margin: 5px 10px;
  border-radius: 5px;
  color: white;
}

.monthGlanceCardRowTitle {
  text-align: center;
  font-size: 18px;
}

.monthGlanceCardRowTitleHeaders {
  text-align: center;
  text-decoration: underline;
  font-size: 16px;
}

.monthGlanceCard {
  border: 3px solid var(--rm-blue);
  background-color: #ffffff !important;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 4px 4px 3px 3px rgba(0, 0, 0, 0.45) !important;
}

.monthGlanceCard:focus {
  background-color: #4e6073 !important;
  color: white !important;
}

.monthGlanceCardRep {
  border: 3px solid var(--rm-blue);
  background-color: #4e6073 !important;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 4px 4px 3px 3px rgba(0, 0, 0, 0.45) !important;
}

.cardDash {
  padding-left: 0;
  padding-right: 0;
  min-width: 310px;
  max-width: 390px;
  width: 100%;
  margin-bottom: 10px;
}
.cardHeaderDash {
  background-color: var(--rm-blue) !important;
  color: #ffffff !important;
  font-weight: bold;
  border: 1px solid #ffffff;
  box-shadow: 4px 4px 3px 3px rgba(0, 0, 0, 0.09) !important;
}
.cardBodyDash {
  mask-image: linear-gradient(
    to bottom,
    black calc(100% - 18px),
    transparent 100%
  );
  padding: 10px 5px 5px;
  height: 220px;
  overflow-y: auto;
}

.cardDashDiv {
  display: flex;
  justify-content: center !important;
}
.cardDashRow {
  margin: 0 3% 0 3%;
}
/* width */
.cardFooterDash::-webkit-scrollbar,
.cardBodyDash::-webkit-scrollbar {
  width: 7px;
}
/* Track */
.cardFooterDash::-webkit-scrollbar-track,
.cardBodyDash::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
.cardFooterDash::-webkit-scrollbar-thumb,
.cardBodyDash::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
.cardFooterDash::-webkit-scrollbar-thumb:hover,
.cardBodyDash::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.statusCard div:first-child {
  height: 0;
}
.dbStatusCardsSales {
  text-align: center;
  color: #000000;
  font-size: 1em;
}
.dbStatusCardsSalesService {
  text-align: center;
  color: #000000;
  font-size: 1em;
}

.listOfReasons {
  text-align: right;
  color: #0d47a1;
  height: 100% !important;
}
.listOfReasons:last-child {
  padding-bottom: 8px;
}
.listOfReasons div {
  height: auto !important;
}
.listOfReasonsService {
  text-align: right;
  color: #0d47a1;
  height: 100% !important;
}

.listOfReasonsService div {
  height: auto !important;
}
.listOfReasonsService:last-child {
  padding-bottom: 8px;
}

.pipeLineHover {
  max-width: 200px !important;
}
