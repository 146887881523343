.side-nav {
	background-image: linear-gradient(
		to right,
		rgba(30, 105, 222, 1) 0%,
		rgba(84, 163, 238, 1) 50%,
		rgba(109, 179, 242, 1) 100%
	) !important;
	width: 300px;
	overflow-y: auto;
}

.side-nav .filterHeaders {
	font-size: 20px !important;
}

.filterForms .md-form label,
.filterForms .md-form input,
.filterForms .md-form p,
.filterForms .md-form span,
.filterForms div::before {
	color: #ffffff;
	border-bottom-color: #ffffff;
}

.filterForms .md-form div,
.md-form label {
	padding-left: 1.5rem;
}

.md-form div,
.md-form label {
	padding-left: 1.5rem;
}

.statusReasons {
	font-size: 14px;
}

.statusRowContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	vertical-align: center;
}

.statusRowContainerIcon {
	padding-top: 1rem;
	font-size: 16px;
}

.userFiltersModal .form-check-input,
.userFiltersModal .form-check {
	margin: 0 !important;
}

.userFiltersModal div .md-form {
	margin: 0 !important;
}

.filterEquipmentList {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.filterEquipmentList .form-check {
	margin: 0.2rem !important;
}

.filterEquipmentList div label {
	margin-right: 0.2rem !important;
}

.dropdown-content {
	max-height: 350px;
}
