// Parallax
.jarallax-keep-img {
  position: relative;
  z-index: 0;
}
.jarallax-keep-img > .jarallax-img {
  position: relative;
  display: block;
  max-width: 100%;
  height: auto;
  z-index: -100;
}
span[data-jarallax-element] {
  display: inline-block;
}

.jarallax {
  min-height: 600px;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
