.inner {
	position: absolute;
	border-radius: 10px;
	transition: all 0.7s ease-in-out;
	cursor: pointer;
	/*clip-path: circle(50%);*/
}

.inner h1 {
	margin: 0;
}

.inner p {
	color: rgba(255, 255, 255, 0);
	font-size: 0.9rem;
}

.inner span {
	transition: color 0.7s;
	position: relative;
}

.inner:hover span {
	color: rgba(255, 255, 255, 0);
}

.inner:hover p {
	color: white;
}

.inner:hover {
	clip-path: circle(75%);
	z-index: 1000;
	max-width: 30%;
	white-space: initial;
	padding: 15px;
	margin-left: -3%;
	background: #3f729b;
	width: auto !important;
	height: auto !important;
}

.techTooltipBoxOuter {
	position: absolute;
	top: 5%;
	left: 20%;
	z-index: 50000;
	max-width: 500px;
	max-height: 50%;
}

.techTooltipBox {
	background: rgb(37, 141, 200);
	background: -moz-linear-gradient(
		top,
		rgba(37, 141, 200, 1) 0%,
		rgba(19, 136, 198, 1) 100%
	);
	background: -webkit-linear-gradient(
		top,
		rgba(37, 141, 200, 1) 0%,
		rgba(19, 136, 198, 1) 100%
	);
	background: linear-gradient(
		to bottom,
		rgba(37, 141, 200, 1) 0%,
		rgba(19, 136, 198, 1) 100%
	);
	color: #fff;
	border-radius: 5px;
	box-shadow: 5px 5px 5px #818181;
}

.techTooltipBoxDelivery {
	background: rgb(37, 200, 135);
	background: -moz-linear-gradient(
			top,
			rgb(42, 200, 37) 0%,
			rgb(31, 198, 19) 100%
	);
	background: -webkit-linear-gradient(
			top,
			rgb(42, 200, 37) 0%,
			rgb(31, 198, 19) 100%
	);
	background: linear-gradient(
			to bottom,
			rgb(42, 200, 37) 0%,
			rgb(31, 198, 19) 100%
	);
	color: #fff;
	border-radius: 5px;
	box-shadow: 5px 5px 5px #818181;
}

.techTooltipBoxInner {
	margin: auto;
	padding: 5px;
}

.wrapper {
	width: 10px;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(3, 25vw);
	grid-template-rows: repeat(5, auto);
	grid-auto-flow: column;
}


.idBulletContainer text{
	color: white;
	fill: white !important;
}

