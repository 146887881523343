.last-note-tooltip {
	max-width: 30%;
	white-space: pre-wrap;
	background-color: black !important;
}

.date-picker {
	min-width: 300px;
}


.date-col {
	margin-right: 55px !important;
}
 
@media screen and (max-width: 767px) {
	.filtersCard {
		padding-top: 1.25rem;
		min-height: 40vh;
	}
}