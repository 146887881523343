.dx-g-bs4-table-container {
	background-color: #696969;
}

.ordersListGrid {
	max-height: 86vh;
	overflow-y: auto;
}

.purchasingGrid .card-footer {
	background-color: #5881C1;
}

.purchasingGrid .card-footer nav ul li,
.purchasingGrid .card-footer nav ul li .page-link {
	color: #ffffff;
}

.purchasingGrid table {
	background-color: transparent;
	color: #000;
}

.purchasingGrid [class^="TableContainer-root"] {
	background-color: #ffffff;
}

.purchasingGrid tfoot {
	display: none;
}

.purchasingGrid thead [class^="Title-title"] {
	overflow: hidden;
}

.purchasingGrid thead tr {
	height: 40px;
}

.purchasingGrid tbody tr {
	height: 30px;
}

.purchasingGrid thead tr th {
	color: white;
	text-decoration-line: underline;
	font-size: 12px;
	padding: 0;
}

/*purchasingGrid body alternating colors*/
.purchasingGrid tbody tr:nth-child(even) {
	background: #aaaaaa44;
}
.purchasingGrid tbody tr:nth-child(odd) {
	background: #dddddd44;
}

/*purchasingGrid header colors*/
/*.purchasingGrid div:nth-child(1){*/
/*    color: #ffffff;*/
/*    background-color: transparent;*/
/*}*/

/*purchasingGrid grid spacer color*/
.purchasingGrid div div:nth-child(2) table thead tr th div:nth-child(2) div {
	background-color: white !important;
}

/*expenses grid pagination position*/
.expensesGrid div div:nth-child(2) {
	display: flex;
	justify-content: center;
	color: white;
}

/*expenses grid pagination color*/
.expensesGrid div div:nth-child(2) div span {
	color: white !important;
}

/*purchasingGrid grouping buttons*/
.purchasingGrid div[role="button"] {
	background-color: #5881C1 !important;
	font-size: 12px;
	color: white !important;
}

/*purchasingGrid pagination position*/
.purchasingGrid div div:nth-child(3) {
	background-color: #dddddd;
	padding: 0;
	/*border: 1px solid #444;*/
	display: flex;
	justify-content: center;
}

/*purchasingGrid pagination color*/
.purchasingGrid div div:nth-child(3) div span {
	color: dimgrey !important;
}

.purchasingGrid .btn {
	outline-color: #778899;
	color: #ffffff !important;
	border-color: #000000 !important;
}

.purchasingGrid tr:first-child {
	background-color: #5881C1;
}
.purchasingGrid tr{
	background-color: #ffffff;}

.purchasingGrid tr:nth-child(2) th div{
	max-width: 100px;
	border: 1px dotted;
	border-radius: 3px;
	border-color: rgba(149, 157, 168, 0.8);
	}

.purchasingGrid table tbody tr td {
	color: #000;
	font-size: 12px;
	padding: 0;
	white-space: pre-wrap;
}

.purchasingGrid table tbody tr:hover {
	background-color: #1565c0;
	opacity: 0.4;
	color: #ffffff;
}

.dx-g-bs4-table-sticky {
	z-index: 0 !important;
}

.dx-g-bs4-paging-panel {
	display: flex;
	justify-content: center;
}

.dx-g-bs4-paging-panel nav {
	float: none !important;
}

.dmgSample {
	padding-top: 1px;
	border-top: 1px solid #dee2e6;
	z-index: 1000;
}

.cellOverride {
	border-top: 0 !important;
}

.note-tooltip {
	max-width: 30%;
	white-space: pre-wrap;
	z-index: 500000;
}

.filterDateOptions .md-form label {
	top: -8px;
	font-size: 13px;
}

.purchaseNav{
	background-color: #5881C1 !important;
	width: 100%;
	padding-top: 15px !important;
	margin-left: 0;
}
