.activityHeader {
	text-align: end;
	position: absolute;
	right: 10px;
}

.individualNote {
	justify-content: space-between;
}

.notesHeaderRear {
	color: #ffffff;
	text-align: end;
	position: absolute;
	right: 1.5em;
}

.dropdown-menu {
	max-height: 250px;
	overflow-y: auto;
	overflow-x: hidden;
}

.activity-header {
	border-radius: 10px 10px 0px 0px;
	margin-left: 0px !important;
	width: 100% !important;
	background-color: #5881C1 !important;
	box-shadow:  3px -5px 20px #424242 !important;
}

.activity-body {
	background-color: #FFFFFF !important;
	border-radius: 10px;
	box-shadow: 3px 3px 20px #424242;
}

